// import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import HeaderFooterContext from '@/context/HeaderFooterContext';
import imgLink from '@/public/images/logos/Access_Storage_Logo_RGB.svg';
// import imgLink from '@/public/images/logos/Access-25th-Logo.svg';
import SetBrandsContext from '@/services/home/setBrands';
import { BRAND_NAME_EN, BRAND_NAME_FR } from '@/utils/constants';

import styles from './Header.module.scss';
import MobileHeader from './mobile-header';
import RightPanelMenu from './right-panel-menu';

const Header = () => {
  const { locale }: any = useRouter();

  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;

  const isContext = !!headerFooter[0];
  const [brands, setBrands] = useState<any>([]);
  const getContextData = async () => {
    let defaultBrand = BRAND_NAME_EN;
    if (locale === 'fr') defaultBrand = BRAND_NAME_FR;
    const contextData = await SetBrandsContext(defaultBrand, locale);
    setBrands(contextData?.data);
  };
  useEffect(() => {
    getContextData();
  }, [isContext, locale]);

  const [expa, setExpa] = useState(false);
  const [showClass, setShowClass] = useState({ display: 'none' });
  const [height, setHeight] = useState(false);
  const [heightStyle, setHeightStyle] = useState({ height: '0' });
  const handleExpand = () => {
    setExpa(!expa);
    if (expa) {
      setShowClass({ display: 'none' });
      document.getElementById('nav-icon3').classList.remove('open');
    } else {
      setShowClass({ display: 'block' });
      setTimeout(() => {
        document.getElementById('storageDropdown').click();
      }, 100);
      document.getElementById('nav-icon3').classList.add('open');
    }
    setHeight(!height);
    if (height) setHeightStyle({ height: '0' });
    else setHeightStyle({ height: '600px' });
  };

  const handleCLose = () => {
    setExpa(false);
    setShowClass({ display: 'none' });
    setHeight(false);
    setHeightStyle({ height: '0' });
    document.getElementById('nav-icon3').classList.remove('open');
  };

  return (
    <>
      {/* Responsive view before scroll */}
      <header>
        <nav className={`${styles['sv-navbar']} navbar sv-header`}>
          <div className="container-xl custom-container px-0">
            <div className="nav-section">
              <div className="leftSide-nav">
                <MobileHeader
                  showClass={showClass}
                  heightStyle={heightStyle}
                  handleExpand={handleExpand}
                />
                <Link href="/">
                  <a className="navbar-brand" onClick={handleCLose}>
                    {brands?.length ? (
                      <Image
                        src={brands[0]?.attributes?.Logo?.data?.attributes?.url}
                        alt="logo"
                        width="130"
                        height="60"
                        priority={true}
                      />
                    ) : (
                      <Image
                        src={imgLink}
                        alt="logo"
                        width="130"
                        height="60"
                        priority={true}
                      />
                    )}
                  </a>
                </Link>
                <div className="navbar-brand" onClick={handleCLose}>
                  {brands[0]?.attributes?.secondary_logo?.data?.attributes
                    ?.url ? (
                    <Image
                      src={
                        brands[0]?.attributes?.secondary_logo?.data?.attributes
                          ?.url
                      }
                      alt="secondary-logo"
                      width="130"
                      height="60"
                      priority={true}
                    />
                  ) : null}
                </div>
              </div>
              <div className="rightSide-nav" onClick={handleCLose}>
                <RightPanelMenu></RightPanelMenu>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
